@font-face {
	font-family: 'Neue Montreal';
	src: url('./assets/PPNeueMontreal-Medium.woff2') format('woff2');
	font-style: normal;
}

@font-face {
	font-family: 'Libre Baskerville';
	src: url('./assets/libre-baskerville-v14-latin-regular.woff2') format('woff2');
	font-style: normal;
}
